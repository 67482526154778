<template>
  <div>
    <Nav />
    <div class="center" style="background: #fff;padding: 30px;">
        <div class="info-container">
            <div class="item">
                <div class="top">
                    <div class="left">
                        <el-tag>公告期</el-tag>
                        <h3 class="ml10">【德土告字[2023]110号】ZP2023-110号</h3>
                    </div>
                    <div>
                        <el-tag color="rgb(249,84,37)" style="color:#fff;margin-right: 10px;">国有土地</el-tag>
                        <el-tag color="rgb(143,197,73)" style="color:#fff;margin-right: 10px;">挂牌</el-tag>
                        <el-tag color="rgb(144,39,140)" style="color:#fff">出让</el-tag>
                    </div>
                </div>
                <el-row :gutter="20">
                    <el-col :span="6"  style="margin-top:15px;">
                        位置：XXX区xxx
                    </el-col>
                    <el-col :span="6" style="margin-top:15px;">
                        规划用途：公告管理与公共服务
                    </el-col>
                    <el-col :span="6" style="margin-top:15px;">起始价：5100万元</el-col>
                    <el-col :span="6" style="margin-top:15px;">保证金：5100万元</el-col>
                    <el-col :span="6" style="margin-top:15px;">挂牌开始时间：2023-02-25</el-col>
                    <el-col :span="6" style="margin-top:15px;">挂牌结束时间：2023-06-30</el-col>
                    <el-col :span="6" style="margin-top:15px;">保证金截止时间：2023-02-25</el-col>
                </el-row>
                <div style="margin-top:15px;">
                    倒计时：
                    <span class="time mr10">60</span>
                    <span class="mr10">天</span>
                    <span class="time mr10">12</span>
                    <span class="mr10">时</span>
                    <span class="time mr10">12</span>
                    <span class="mr10">分</span>
                    <span class="time mr10">32</span>
                    <span class="mr10">秒</span>
                </div>
                <div class="mt15">
                    当前报价：
                    <span class="no">暂无报价</span>
                    <span class="ml10 mr10">|</span>
                    <span>0次报价</span>
                </div>
            </div>
            <div class="l-c">
                <div class="title">流程竞标</div>
                <div style="margin-top:60px">
                    <el-steps :active="1">
                        <el-step title="公告开始" description="2023-02-22 8:00:00"></el-step>
                        <el-step title="公告结束" description="2023-05-22 8:00:00"></el-step>
                        <el-step title="挂牌开始" description="2023-05-22 8:00:00"></el-step>
                        <el-step title="保证金缴纳截止时间" description="2023-05-22 8:00:00"></el-step>
                    </el-steps>
                </div>
            </div>
            <div class="land-info"  style="margin-top:60px">
                <div class="title">土地详情</div>
                <el-table :data="tableData" style="width: 100%" border :span-method="arraySpanMethod">
                    <el-table-column prop="date" label="土地名称"> </el-table-column>
                    <el-table-column prop="date" label="镇土公告" > </el-table-column>
                    <el-table-column prop="date" label="土地编号"> </el-table-column>
                    <el-table-column prop="date" label="ZP2022-1C9号"> </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
    <footer2 />
  </div>
</template>

<script>
 import Nav from '@/components/nav'
 import footer2 from "components/footer.vue";
export default {
    name: 'tradingServices',
    components:{
        Nav,
        footer2
    },
    data(){
        return{
            tableData: [{
            date: '0',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
          }, {
            date: '1',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1517 弄'
          }, {
            date: '2',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
          }, {
            date: '3',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1516 弄'
          }]
        }
    },
    methods:{
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            console.log(row,column)
        if (rowIndex % 2 === 0) {
          if (columnIndex === 0) {
            return [1, 2]
          } else if (columnIndex === 1) {
            return [0, 0];
          }
        }else{
            return [1, 0]
        }
      },
    }
}
</script>

<style lang="scss" scoped>
   .title{
                padding-bottom: 10px;
                border-bottom: 1px solid #ccc;
                color: #409EFF;
                font-size: 18px;
                text-align: center;
            }
    .info-container{
        font-size: 14px;
        .item{
            .top{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left{
                    display: flex;
                    align-items: center;
                }

            }
            .time{
                background: #409EFF;
                padding: 5px 5px;
                border-radius: 5px;
                color: #fff;
                font-size: 18px;
                // margin-right: 10px;
            }
            .no{
                font-size: 18px;
                color: red;
            }
        }
        .l-c{
           
        }
    }
</style>

